import produce from 'immer';
import create, { StateCreator } from 'zustand';

export interface UserSetting {
  [key: string]: string;
}
export type ChartbitState = {
  changes: {
    haveChanges: boolean;
    modalCallback: null | ((value: boolean) => void);
    layoutIdToLoad: null | string | number;
  };
  currentLayoutId: any;
  myInvestment: {
    visible: boolean;
  };
  createdLayoutId: any;
  activeModalUnsaved: string;
  widgetKey: string;
  isOpenNewLayout: boolean;
  showOverlay: boolean;
  userSettings: UserSetting;
  currentContent: any;
  lastActiveWidgetKey: string;
};

export type ChartbitActions = {
  closeUnsavedModal: () => void;
  openUnsavedModal: (widgetKey: string) => Promise<boolean>;
  setHaveChanges: (value: boolean) => void;
  setVisibleMyInvestment: (value: boolean) => void;
  setCurrentLayoutId: (value: any) => void;
  setCreatedLayoutId: (value: any) => void;
  setWidgetKey: (value: string) => void;
  setIsOpenNewLayout: (value: boolean) => void;
  setShowOverlay: (value: boolean) => void;
  setUserSetting: ({
    key,
    value,
  }: {
    key: string;
    value: string;
  }) => Promise<UserSetting>;
  setBulkUserSetting: (value: UserSetting) => void;
  setCurrentContent: (value: any) => void;
  setLastActiveWidgetKey: (value: string) => void;
};

export type ChartbitStore = ChartbitState & ChartbitActions;

const chartbitState: ChartbitState = {
  changes: {
    haveChanges: false,
    modalCallback: null,
    layoutIdToLoad: null,
  },
  currentLayoutId: null,
  myInvestment: {
    visible: false,
  },
  createdLayoutId: null,
  activeModalUnsaved: null,
  widgetKey: null,
  isOpenNewLayout: false,
  showOverlay: true,
  userSettings: {},
  currentContent: {},
  lastActiveWidgetKey: null,
};

const chartbitActions: StateCreator<ChartbitStore, [], [], ChartbitActions> = (
  set,
  get,
) => ({
  closeUnsavedModal: () => {
    set((state) =>
      produce(state, (draft) => {
        draft.changes.modalCallback = null;
        draft.activeModalUnsaved = null;
      }),
    );
  },
  openUnsavedModal: (widgetKey: string) =>
    new Promise((resolve) => {
      const cb = (value: boolean) => {
        resolve(value);
        get().closeUnsavedModal();
      };

      set((state) =>
        produce(state, (draft) => {
          draft.activeModalUnsaved = widgetKey;
          draft.changes.modalCallback = cb;
        }),
      );
    }),
  setHaveChanges: (value) => {
    set((state) =>
      produce(state, (draft) => {
        draft.changes.haveChanges = value;
      }),
    );
  },
  setVisibleMyInvestment: (value: boolean) => {
    set({ myInvestment: { visible: value } });
  },
  setCurrentLayoutId: (value: any) => {
    set({ currentLayoutId: value });
  },
  setCreatedLayoutId: (value: any) => {
    set({ createdLayoutId: value });
  },
  setWidgetKey: (value: any) => {
    set({ widgetKey: value });
  },
  setIsOpenNewLayout: (value: boolean) => {
    set({ isOpenNewLayout: value });
  },
  setShowOverlay: (value: boolean) => {
    set({ showOverlay: value });
  },
  setUserSetting: ({ key, value }: { key: string; value: string }) =>
    new Promise((res) => {
      const current = get().userSettings;
      const newSetting = { ...current, [key]: value };
      set({ userSettings: newSetting });
      res(newSetting);
    }),
  setBulkUserSetting: (setting: UserSetting) => {
    set({ userSettings: setting });
  },
  setCurrentContent: (value: any) => {
    set({ currentContent: value });
  },
  setLastActiveWidgetKey: (value: string) => {
    set({ lastActiveWidgetKey: value });
  },
});

export const useChartbitStore = create<ChartbitStore>(
  (set, get, store, storeMutation) => ({
    ...chartbitState,
    ...chartbitActions(set, get, store, storeMutation),
  }),
);
